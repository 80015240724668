<template>
  <div class="installationWebhookList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(installationWebhooks)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(installationWebhooks)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="dateCell"
          slot-scope="{props}"
        >
          <td>
            {{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
        <template
          slot="booleanTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <input
              :checked="kgm_getNestedValue(props.field, props.dataItem)"
              type="checkbox"
              disabled
              readonly
            >
          </td>
        </template>
        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an ID"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="timestampFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="timestampFilterValue"
            :value="props.value"
            type="date"
            class="form-control mr-2 rounded-right"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('timestampFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="webhookFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="webhookFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an Webhook"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('webhookFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="parameterFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="parameterFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an Parameter"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('parameterFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="retriesFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="retriesFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter Retries amount"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('retriesFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="retryTimestampFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="retryTimestampFilterValue"
            :value="props.value"
            type="date"
            class="form-control mr-2 rounded-right"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('retryTimestampFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "InstallationWebhookList",
  mixins: [
    kendoGridMixin,
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      installationWebhooks: null,
      kgm_sort: [
        {
          "field": "timestamp",
          "dir": "desc"
        }
      ],
      kgm_columns: [
        {
          field: 'id',
          filterable: true,
          filter: 'text',
          title: 'ID',
          filterCell: "idFilter",
          hideOn: ['xlDown']
        },
        {
          field: 'timestamp',
          filterable: true,
          filter: 'date',
          title: this.$t('videoPublisherDetailsComp.timestamp'),
          cell: "dateCell",
          filterCell: "timestampFilter"
        },
        {
          field: 'webhook',
          filterable: true,
          filter: 'text',
          title: 'Webhook',
          filterCell: "webhookFilter"
        },
        {
          field: 'webhookParameter',
          filterable: true,
          filter: 'text',
          title: 'Parameter',
          filterCell: "parameterFilter",
          hideOn: ['xsDown']
        },
         {
          field: 'successful',
          filterable: true,
          filter: 'boolean',
          title: this.$t('successful'),
          cell: 'booleanTemplate'
        },
        {
          field: 'retries',
          filterable: true,
          filter: 'text',
          title: this.$t('retries'),
          filterCell: "retriesFilter"
        },
         {
          field: 'retryTimestamp',
          filterable: true,
          filter: 'text',
          title: this.$t('retryTimestamp'),
          cell: "dateCell",
          filterCell: "retryTimestampFilter",
          hideOn: ['mdDown']
        },
      ]
    }
  },
  created () {
    this.getInstallationWebhooks();
  },
  methods: {
    getInstallationWebhooks () {
      this.loading = true;
      this.axios.get(`/Audit/GetInstallationWebhookAudits?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.installationWebhooks = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.installationWebhookList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>